/* eslint-disable max-len */
import { merge } from "lodash";
import { esCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const es: BaseLang = merge(esCommon, {
    apps: {
        account: {
            name: "Cuenta",
            description: "Gestione su cuenta y su organización."
        },
        timestamping: {
            name: "Timestamping",
            description: "Demuestre la fecha exacta y la integridad de los datos de sus documentos importantes." // to review
        },
        sealing: {
            name: "Sellado",
            description: "Garantice la integridad y la autenticidad de sus documentos importantes." // to review
        },
        archiving: {
            name: "Archiving",
            description: "Archive sus datos de forma segura y a largo plazo." // to review
        }
    },
    register: {
        slogan: "Trust reinvented"
    },
    onboarding: {
        title: "¡Bienvenidos a Evidency!",
        subtitle: "Especialista en la gestión del ciclo de vida de la prueba digital."
    }
});
